import React, { useEffect, useState } from "react";
import r2wc from "@r2wc/react-to-web-component";
import DefaultChatIcon from "./defaultChat";
import DownArrow from "./DownArrow";
import CloseIcon from "./closeicon";
import { debounce } from "lodash";
import "./index.css";

type WidgetProps = {
  apikey: string;
  hosturl?: string;
};

const Widget: React.FC<WidgetProps> = ({
  apikey,
  hosturl = "https://app.isosceles.ai",
}: WidgetProps) => {
  const [opened, setOpened] = useState(false);
  const [keepOpen, setKeepOpen] = useState(false);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = debounce(
      () => setIsMobile(window.innerWidth < 768),
      500
    );
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (!keepOpen && opened) {
      setKeepOpen(true);
    }
  }, [opened, keepOpen]);

  return (
    <>
      {keepOpen && (
        <div
          id="iframe"
          style={{
            opacity: "1",
            position: "fixed",
            boxShadow: "rgba(150, 150, 150, 0.2) 0px 10px 30px 0px",
            overflow: "hidden",
            bottom: isMobile ? "0" : "80px",
            right: isMobile ? "0" : "1rem",
            height: isMobile ? "100vh" : "70vh",
            maxHeight: isMobile ? "100vh" : "750px",
            width: isMobile ? "100vw" : "450px",
            borderRadius: isMobile ? "0px" : "0.75rem",
            zIndex: "50",
            border: "1px solid rgb(229, 231, 235)",
            background: "transparent",
            display: opened ? "flex" : "none",
          }}
        >
          <iframe
            title="Isosceles.ai Chatbot"
            src={`${hosturl}/embed/chatbot/${apikey}`}
            style={{ width: "100%", height: "100%", border: "none" }}
          />
          <button
            id="close-button"
            onClick={() => setOpened(false)}
            aria-label="Close"
            style={{
              position: "absolute",
              right: "18px",
              top: "18px",
              border: "none",
              background: "none",
              zIndex: "50",
            }}
          >
            <CloseIcon />
          </button>
        </div>
      )}
      {(!opened || !isMobile) && (
        <button
          type="button"
          id="widget-button"
          style={{
            width: "50px",
            height: "50px",
            borderRadius: "9999px",
            boxShadow: "rgba(0, 0, 0, 0.2) 0px 4px 8px 0px",
            cursor: "pointer",
            zIndex: "50",
            border: "0px",
            transition: "all 0.2s ease-in-out 0s",
            color: "white",
            backgroundColor: "rgb(124, 58, 237)",
            position: "fixed",
            bottom: "1rem",
            padding: "0px",
            right: "1rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={() => setOpened(!opened)}
        >
          {opened ? <DownArrow /> : <DefaultChatIcon />}
        </button>
      )}
    </>
  );
};

export const AthenaWidget = r2wc(Widget, {
  props: {
    apikey: "string",
    hosturl: "string",
  },
});

customElements.define("isosceles-widget", AthenaWidget);

export default Widget;
