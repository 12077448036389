const DownArrow = () => {
    return (
        <svg width="20" height="21" viewBox="0 0 20 21" fill="#000" xmlns="http://www.w3.org/2000/svg" alt="close-icon" style={{ width: '25px', height: '25px', filter: 'invert(100%)', display: 'flex' }}>
            <path d="M17.5 5.25L10 12.75L2.5 5.25L1 6.75L10 15.75L19 6.75L17.5 5.25Z" />
        </svg>

    )
}

export default DownArrow;
